// src/DynamicPage.js
import React from 'react';
import { useParams, Navigate } from 'react-router-dom';
import routes from './Routes' // Import the routes object

const DynamicPage = () => {
    const { slug } = useParams();
    
    // Check if the slug corresponds to a valid route
    const route = Object.values(routes).find(route => route.slug === slug);

    if (route) {
        const Component = route.component;
        return <Component />; // Render the corresponding component
    }

    // If the route doesn't match any valid slug, redirect or render a 404
    return <Navigate to="/404" />; // Redirect to a 404 page or render a fallback component
};

export default DynamicPage;
