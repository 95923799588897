import React from 'react';
import { Link } from 'react-router-dom';

const ArticleCard = ({ titlu, image_url, url, url_external }) => {

  const imageSrc = image_url && url ? `${url}${image_url}` : 'fallback-image-url';

  const isExternal = Boolean(url_external);

  const cardContent = (
    <div className="relative overflow-hidden rounded-lg h-[214px]">
      <img
        className='w-full h-full object-cover transition-transform duration-300 group-hover:scale-105'
        src={imageSrc}
        alt={titlu ? titlu : 'Article Image'}
      />
      {/* Shadow Mask */}
      <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent"></div>
      <span className='absolute inset-0 flex items-end justify-start pl-3 pb-3 text-white text-[24px] sm:text-[36px] lg:text-[44px] font-medium'>
        {titlu || 'Se incarca...'}
      </span>
    </div>
  );

  return isExternal ? (
    <a 
      href={url_external} 
      target='_blank' 
      rel="noopener noreferrer" 
      className="relative w-full transition-all duration-300 group"
      aria-label={`Link to external article: ${titlu}`}
    >
      {cardContent}
    </a>
  ) : (
    <Link 
      to={url} 
      className="relative w-full transition-all duration-300 group"
      aria-label={`Link to internal article: ${titlu}`}
    >
      {cardContent}
    </Link>
  );
}

export default ArticleCard;
