import React from 'react'
import ArticleCard from './ArticleCard'
import HeaderText from '../HeaderText/HeaderText'


import UrlContext from '../../contexts/globalContext'
import { LanguageContext } from '../../contexts/languageContext'
import { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import QueryString from 'qs'

const NoutatiRecenteSidebar = () => {

  const { languages, currentLangIndex } = useContext(LanguageContext);
  const apiUrl = useContext(UrlContext);
  const url = apiUrl[0];
  const apikey = apiUrl[1];

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [cards, setCards] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = QueryString.stringify({
          locale: languages[currentLangIndex],
          populate: {
            ComponentaLaterala: {
              populate: "*"
            }
          }
        });

        // Fetch data for the specific page using its ID
        const response = await axios.get(`${url}/api/carduri-laterale-navigare?${query}`, {
          headers: {
            Authorization: `Bearer ${apikey}`
          }
        });

        setCards(response.data.data.attributes.ComponentaLaterala);
      } catch (err) {
        console.error("Error fetching page data:", err);
        setError("Failed to load data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [url, currentLangIndex]);



  return (
    <div className='mt-[40px] flex items-start flex-col gap-[24px] pt-[32px] w-full'>
      {cards ?
        <>
          <HeaderText classes={""} text={"RECENTE"} />
          {cards.map((_, index) => (
            <div key={index} className="w-full">

              <ArticleCard
                url={url ? url : "/"}
                titlu={cards[index] ? cards[index].Titlu : ""}
                image_url={cards[index] ? cards[index].Banner.data.attributes.url : ""}
                url_external={cards[index] ? cards[index].Url : "#"}
              />
            </div>
          ))}
        </>
        : "Se incarca..."}
    </div>
  )
}

export default NoutatiRecenteSidebar
