import { createContext, useState } from 'react';

const LanguageContext = createContext();

const LanguageProvider = ({ children }) => {
  const [languages, setLanguages] = useState(["ro-MD", "ru"]); // initial languages array
  const [currentLangIndex,  setCurrentLangIndex] = useState(0);

  return (
    <LanguageContext.Provider value={{ languages, setLanguages, currentLangIndex, setCurrentLangIndex }}>
      {children}
    </LanguageContext.Provider>
  );
};

export { LanguageProvider, LanguageContext };