import QueryString from 'qs';
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { LanguageContext } from '../contexts/languageContext';
import UrlContext from '../contexts/globalContext';
import Loader from '../components/Loader/Loader';
import HeaderText from '../components/HeaderText/HeaderText';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';

import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/ReactToastify.css';

const Contacte = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stateMessage, setStateMessage] = useState(null);

  const sendEmail = (e) => {
    e.persist();
    e.preventDefault();
    setIsSubmitting(true);

    // Display loading toast immediately upon form submission
    const loadingToastId = toast.loading("Se trimite...");

    emailjs.sendForm(
      'service_mscagkc',
      'contact_form',
      e.target,
      '195szt8YbJ2qdRQja'
    ).then(
      (result) => {
        toast.update(loadingToastId, {
          render: "Mesajul a fost trimis cu succes!",
          type: "success",
          isLoading: false,
          autoClose: 2000 // Close after 2 seconds
        });
        setIsSubmitting(false);
        setStateMessage('Mesajul a fost trimis cu succes!');
        setTimeout(() => {
          setStateMessage(null);
        }, 5000); // Hide message after 5 seconds
      },
      (error) => {
        toast.update(loadingToastId, {
          render: "Ceva nu a mers cum trebuie. Incearca inca odata",
          type: "error",
          isLoading: false,
          autoClose: 2000 // Close after 2 seconds
        });
        setIsSubmitting(false);
        setStateMessage('Ceva nu a mers cum trebuie. Incearca inca odata');
        setTimeout(() => {
          setStateMessage(null);
        }, 5000); // Hide message after 5 seconds
      }
    );
    e.target.reset();
  };


  const [phoneNumber, setPhoneNumber] = useState('');

  const handleChange = (event) => {
    const value = event.target.value;
    if (/^\d*?$/.test(value) || value === '') {
      setPhoneNumber(value);
    }
  };

  const { languages, currentLangIndex } = useContext(LanguageContext);
  const apiUrl = useContext(UrlContext);
  const url = apiUrl[0];
  const apikey = apiUrl[1];

  const [loading, setLoading] = useState(true);
  const [contacts, setContacts] = useState([]);
  const [banner, setBanner] = useState([]);

  useEffect(() => {
    const query = QueryString.stringify({
      locale: languages[currentLangIndex],
      populate: "*"
    });

    axios.get(`${url}/api/contacte?${query}`, {
      headers: {
        Authorization: `Bearer ${apikey}`
      }
    }).then(response => {
      setContacts(response.data.data.attributes.Contact);
      setBanner(response.data.data.attributes.Banner.data.attributes.url);
      setLoading(false);
    }).catch(err => {
      console.log("Error fetching contact: ", err);
    });
  }, [url, currentLangIndex]);

  return (
    <>
      <main>
        <ToastContainer position='top-right' autoClose={2000} />
        {loading ? (
          <div className="flex justify-center items-center h-[300px]">
            <Loader size={30} />
          </div>
        ) : (
          <>
            <Header />
            <div className="">
              <img className='object-cover w-full h-[50vh]' src={`${url}${banner}`} alt="Primarie" />
            </div>
            <div className="flex flex-col items-center px-4 md:px-8 lg:px-16 pt-24 pb-12">
              <div className="flex flex-col items-center gap-2">
                <HeaderText classes={""} text={"CONTACTEAZĂ-NE"} />
                <p className='opacity-60 text-center'>
                  Intrebări sau propuneri ? Scrie-ne un mesaj!
                </p>
              </div>
              <div className='grid grid-cols-1 md:grid-cols-5 max-w-[1200px] mx-4 mt-10 gap-4'>
                <div className="col-span-1 md:col-span-2 bg-main p-5 rounded-lg">
                  <HeaderText classes={"text-white"} text={"Informații de contact"} />
                  <div className="mt-10 text-white flex flex-col gap-4 overflow-hidden">
                    {contacts.map((contact) => (
                      <a href={`tel:+${contact.Telefon}`} key={contact.id} className='text-lg font-medium line-clamp-2'>
                        {contact.Descriere}
                      </a>
                    ))}
                  </div>
                </div>
                <form onSubmit={sendEmail} className="col-span-1 md:col-span-3 bg-white p-5 rounded-lg shadow">
                  <div className="flex flex-col md:flex-row gap-4">
                    <div className="flex flex-col w-full">
                      <label className='opacity-60' htmlFor="name">Nume</label>
                      <input type="text" className="border-b-2 border-b-black focus:outline-none py-2" name='name' id='name' />

                      <label className='opacity-60 mt-4' htmlFor="email">Email</label>
                      <input
                        name='email'
                        type="email"
                        id='email'
                        autoComplete='off'
                        className="w-full border-b-2 border-b-black py-2 invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:outline-none"
                      />
                    </div>
                    <div className="flex flex-col w-full">
                      <label className='opacity-60' htmlFor="surname">Prenume</label>
                      <input type="text" className="border-b-2 border-b-black focus:outline-none py-2" name='surname' id='surname' />

                      <label className='opacity-60 mt-4' htmlFor="number">Telefon</label>
                      <input type="tel" id='number' name='number' value={phoneNumber} onChange={handleChange} pattern='[0-9]*' inputMode='numeric' autoComplete='off' className="border-b-2 border-b-black focus:outline-none py-2" />
                    </div>
                  </div>
                  <div className="flex flex-col mt-4">
                    <label htmlFor="message" className='opacity-60'>Scrie-ne un mesaj</label>
                    <textarea name='message' className='focus:outline-none py-2 border-b-black border-b-2' placeholder='Scrie mesajul...' rows="4"></textarea>
                  </div>

                  <input type='submit' value="Trimite" disabled={isSubmitting} className='bg-main text-white px-6 py-2 rounded-md mt-4 hover:bg-blue-600 transition-all hover:cursor-pointer duration-200'/>
                </form>
              </div>
            </div>
            <Footer />
          </>
        )}
      </main>
    </>
  );
}

export default Contacte;
