import QueryString from 'qs';
import UrlContext from '../../contexts/globalContext';
import axios from 'axios';
import React, { useEffect, useState, useContext, useLayoutEffect } from 'react';
import { LanguageContext } from '../../contexts/languageContext';

import { BlocksRenderer } from '@strapi/blocks-react-renderer';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import NoutatiSmallSideBar from '../../components/NoutatiSmallSideBar/NoutatiSmallSideBar';
import NoutatiRecenteSidebar from '../../components/NoutatiRecenteSidebar/NoutatiRecenteSidebar';
import Loader from '../../components/Loader/Loader';

const TypeonePage = ({ slug }) => {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [createdAt, setCreatedAt] = useState('');
    const [banner, setBanner] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    let result = slug.includes("/") ? slug.split("/").pop() : slug;

    const { languages, currentLangIndex } = useContext(LanguageContext);
    const apiUrl = useContext(UrlContext);
    const url = apiUrl[0];
    const apikey = apiUrl[1];

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [slug]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);

            try {
                const query = QueryString.stringify({
                    locale: languages[currentLangIndex],
                    filters: {
                        Titlu: { $eq: result },
                    },
                    populate: "*",
                });

                const response = await axios.get(`${url}/api/template1s?${query}`, {
                    headers: {
                        Authorization: `Bearer ${apikey}`,
                    },
                });
                const rawData = response.data.data[0].attributes;
                setTitle(rawData.TitluVizual);
                setBanner(rawData.Banner.data.attributes.url);
                setContent(rawData.TextPrincipal);
                setCreatedAt(rawData.createdAt);
            } catch (err) {
                console.error("Error fetching page data:", err);
                setError("Nu sunt informatii");
            }
        };

        fetchData();
    }, [url, currentLangIndex, slug]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000)
        return () => clearTimeout(timer);
    }, [slug, title])

    // Error Display
    if (error) {
        return <div className="text-red-500">{error}</div>;
    }

    return (
        <>
            {loading && (
                <div className="fixed inset-0 bg-white flex justify-center items-center z-50">
                    <Loader size={30} />
                </div>
            )}
            <Header />
            <div className="container mx-auto px-4 py-8">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">

                    {/* Main Content Column */}
                    <div className="md:col-span-2 bg-white p-4 md:p-8 rounded-md">
                        {banner ? (
                            <div className="relative">
                                <img src={`${url}${banner}`} alt="Banner" className="w-full h-auto object-contain rounded-md" />
                                <div className="absolute w-full -bottom-8 p-6">
                                    <div className="bg-white shadow-lg py-4 md:p-6 flex justify-center rounded-md">
                                        <p className="text-2xl md:text-4xl font-medium text-gray-800">{title}</p>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <p className="text-gray-500">No banner available</p>
                        )}

                        <article
                            className="mt-8 md:mt-16 text-gray-600 text-base md:text-xl leading-relaxed prose max-w-none"
                            >
                            {content &&
                                <BlocksRenderer
                                    content={content}
                                    blocks={{
                                        list: ({ children, format }) => {
                                            switch (format) {
                                                case "ordered":
                                                    return <ol className="list-decimal pl-5 prose">{children}</ol>;
                                                case "unordered":
                                                    return <ul className="list-disc pl-5 prose">{children}</ul>;
                                                default:
                                                    return null;
                                            }
                                        },
                                        heading: ({ children, level }) => {
                                            const HeadingTag = `h${level}`; // Dynamically set the header tag (h1, h2, ..., h6)
                                            return (
                                                <HeadingTag className={`prose-headings:${HeadingTag} font-semibold my-4`}>
                                                    {children}
                                                </HeadingTag>
                                            );
                                        },
                                    }}
                                />}
                        </article>



                    </div>

                    {/* Sidebar */}
                    <div>
                        <NoutatiSmallSideBar />
                        <NoutatiRecenteSidebar />
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default TypeonePage;
