import React, { useLayoutEffect, useEffect, useState, useContext, useCallback } from 'react';
import { Link, useLocation } from "react-router-dom";


import { FaAngleLeft } from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa";

import NoutatiSmallSideBar from '../components/NoutatiSmallSideBar/NoutatiSmallSideBar';
import NoutatiRecenteSidebar from '../components/NoutatiRecenteSidebar/NoutatiRecenteSidebar';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import HeaderText from '../components/HeaderText/HeaderText';
import Sort from '../assets/site_assets/sort.svg';
import NewsCard from '../components/NewsCard/NewsCard';
import Loader from '../components/Loader/Loader';

import { LanguageContext } from '../contexts/languageContext';
import QueryString from 'qs';
import UrlContext from '../contexts/globalContext';
import axios from 'axios';

const formatedDate = (createdAt) => {
    const date = new Date(createdAt.replace('Z', '+00:00'));
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
};

const Noutati = () => {
    const location = useLocation();
    const { languages, currentLangIndex } = useContext(LanguageContext);
    const apiUrl = useContext(UrlContext);
    const url = apiUrl[0];
    const apikey = apiUrl[1];

    const [news, setNews] = useState([]);
    const [newsTitle, setNewsTitle] = useState('');
    const [sortTitle, setSortTitle] = useState('');
    const [readMoreTitle, setReadMoreTitle] = useState('');
    const [loading, setLoading] = useState(true);

    // Pagination states
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 4;

    useEffect(() => {
        const query = QueryString.stringify({
            locale: languages[currentLangIndex],
            populate: "*"
        });

        // Fetch news titles
        axios.get(`${url}/api/noutati-generale?${query}`, {
            headers: { Authorization: `Bearer ${apikey}` }
        })
            .then(response => {
                console.log(response)
                setNewsTitle(response.data.data.attributes.Titlu);
                setSortTitle(response.data.data.attributes.SorteazaTitlu);
                setReadMoreTitle(response.data.data.attributes.CitesteMaiMultTitlu);
            })
            .catch(err => console.error("Error fetching titles:", err));

        // Fetch news announcements
        axios.get(`${url}/api/noutati-anunturis?${query}`, {
            headers: { Authorization: `Bearer ${apikey}` }
        })
            .then(response => {
                setNews(response.data.data);
            })
            .catch(err => {
                console.error("Error fetching news:", err);
            });
    }, [url, currentLangIndex]);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    const [isAscending, setIsAscending] = useState(true);

    // Handle sorting by date
    const handleSort = useCallback(() => {
        const sortedNews = [...news].sort((a, b) => {
            const dateA = new Date(a.attributes.createdAt).getTime();
            const dateB = new Date(b.attributes.createdAt).getTime();
            return isAscending ? dateA - dateB : dateB - dateA;
        });
        setNews(sortedNews);
        setIsAscending(!isAscending);
        setCurrentPage(1); // Reset to the first page after sorting
    }, [isAscending, news]);

    // Calculate the index of the last news item on the current page
    const indexOfLastNews = currentPage * itemsPerPage;
    // Calculate the index of the first news item on the current page
    const indexOfFirstNews = indexOfLastNews - itemsPerPage;
    // Slice the news array to get the news for the current page
    const currentNews = news.slice(indexOfFirstNews, indexOfLastNews);
    // Calculate total pages
    const totalPages = Math.ceil(news.length / itemsPerPage);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 500);

        return () => clearTimeout(timer);
    }, []);



    return (
        <>
            {loading && (
                <div className="fixed inset-0 bg-white flex justify-center items-center z-50">
                    <Loader size={30} />
                </div>
            )}
            <Header />
            <div className="container mx-auto">
                <main className="px-4 md:px-8 lg:px-16 pt-8 pb-12 grid grid-cols-1 lg:grid-cols-3 gap-8">
                    <div className="md:col-span-1">
                        <NoutatiSmallSideBar />
                        <NoutatiRecenteSidebar />

                    </div>

                    <div className="md:col-span-2 relative flex flex-col">
                        <span className='flex gap-[12px]'>
                            <Link className='opacity-[0.4]' to={'/'}>Acasa</Link>
                            /
                            <p>{newsTitle || "Se incarca..."}</p>
                        </span>

                        <div className="relative mt-[28px] flex-grow">
                            <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-[12px]">
                                <HeaderText classes="text-center md:text-left" text="NOUTATI GENERAL" />
                                <div className="gap-[8px] mt-2 md:mt-0">
                                    <button onClick={handleSort} className="flex items-center gap-[8px] flex-shrink-0">
                                        <h1 className="text-sm md:text-base lg:text-lg">{sortTitle || "Se incarca..."}</h1>
                                        <img src={Sort} alt="Sort Icon" className="w-[20px] h-[20px] md:w-[24px] md:h-[24px]" />
                                    </button>
                                </div>
                            </div>

                            <section className='flex flex-col gap-[24px] mt-[28px]'>
                                {currentNews.map(item => (
                                    <div key={item.id} className="flex justify-center items-center">
                                        <NewsCard
                                            classes={"self-center 2xl:self-start"}
                                            imgClass={"w-full h-full md:w-[170px]"}
                                            img={`${url}${item.attributes.CoverBanner.data.attributes.url}`}
                                            header={item.attributes.Titlu}
                                            content={item.attributes.DescriereJSON}
                                            date={formatedDate(item.attributes.createdAt)}
                                            readMore={readMoreTitle}
                                        />
                                    </div>
                                ))}
                            </section>
                        </div>

                        {/* Pagination Controls */}
                        <div className="flex justify-between">
                            <button
                                onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                                disabled={currentPage === 1}
                                className="px-4 py-2 bg-blue-500 text-white rounded disabled:opacity-50"
                            >
                                <FaAngleLeft />
                            </button>
                            <span>Pagina {currentPage} din {totalPages}</span>
                            <button
                                onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                                disabled={currentPage === totalPages}
                                className="px-4 py-2 bg-blue-500 text-white rounded disabled:opacity-50"
                            >
                                <FaAngleRight />
                            </button>
                        </div>
                    </div>
                </main>
            </div>
            <Footer />
        </>
    );
};

export default Noutati;
