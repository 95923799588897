import React, { useState, useEffect, useContext } from "react";
import { LanguageContext } from "../contexts/languageContext";
import QueryString from "qs";
import UrlContext from "../contexts/globalContext";
import axios from "axios";

const LanguageSelector = () => {
    const [languageIcons, setLanguageIcons] = useState([]);
    const [logo, setLogo] = useState("");
    const [links, setLinks] = useState([]);

    const { languages, currentLangIndex, setCurrentLangIndex } = useContext(LanguageContext);
    const handleLanguageChange = (index) => {
        setCurrentLangIndex(index);
    };

    const query = QueryString.stringify({
        locale: languages[currentLangIndex],
        populate: "*",
    }); 
    const urlApi = useContext(UrlContext);
    const url = urlApi[0];
    const apikey = urlApi[1];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${url}/api/bara-de-navigare?${query}`, {
                    headers: {
                        Authorization: `Bearer ${apikey}`,
                    },
                });
                const data = response.data.data.attributes;
                setLogo(data.Logo.data.attributes.url);
                setLinks(data.Link);
                setLanguageIcons(data.LimbaIconita.data);
            } catch (error) {
                console.log("Error fetching data:", error);
            }
        };

        fetchData();
    }, [currentLangIndex]);

    return (
        <>
            {languageIcons.length > 0 ? (
                languageIcons.map((icon, index) => (
                    <img
                        key={index}
                        src={`${url}${icon.attributes.url}`}
                        alt={`Language ${index}`}
                        onClick={() => handleLanguageChange(index)}
                        className="cursor-pointer w-6 h-6 hover:opacity-80 transition-opacity"
                    />
                ))
            ) : (
                <p className="text-white">Nu sunt limbi</p>
            )}
        </>
    );
};

export default LanguageSelector;
