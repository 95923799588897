import Contacte from "./pages/ContactePage";
import HomePage from "./pages/Home/HomePage";
import NotFoundPage from "./pages/NotFoundPage";
import NoutatiPage from './pages/NoutatiPage'
import NoutatiGeneralPage from './pages/NoutatiGeneralPage'
import TypeonePage from "./pages/Template/TypeonePage";
import Propuneri from "./pages/Propuneri";
import TypetwoPage from "./pages/Template/TypetwoPage";
import ComisideSpecialitate from "./pages/ComisiedeSpecialitate";


const routes = {
    contact: {
        slug: 'Contact',
        component: Contacte,
    },
    notFoundPage: {
        slug: '/404',
        component: NotFoundPage,
    },
    acasa: {
        slug: 'Acasă',
        component: HomePage
    },
    NoutatiPage: {
        slug: "noutati/:header",
        component: NoutatiPage
    },
    NoutatiGeneralPage: {
        slug: "noutati-generale",
        component: NoutatiGeneralPage
    },
    Typeone: {
        slug: "typeone/:idPage",
        component: TypeonePage
    },
    Propuneri: {
        slug: "propuneri/:Titlu",
        component: Propuneri
    },

    // pagini din  menu
    Floreni:{
        slug:"Floreni",
        component: TypeonePage
    },
    Activitati: {
        slug: "Activități",
        component: TypeonePage
    },
    Organizatii_Membre: {
        slug: "Organizații membre",
        component: TypeonePage
    },

    Statistici:{
        slug:"Statistici",
        component: TypeonePage
    },
    Istoric: {
        slug: "Istoric",
        component: TypetwoPage
    },
    Așezare_Geografică: {
        slug: "Așezare Geografică",
        component: TypeonePage
    },

    Contacte:{
        slug:"Contacte",
        component: TypeonePage
    },
    Noutăți: {
        slug: "Noutăți",
        component: NoutatiGeneralPage,
    },
    Turism: {
        slug: "Turism",
        component: TypetwoPage
    },

    Primărie:{
        slug:"Primăria",
        component: TypeonePage
    },
    Primar: {
        slug: "Primar",
        component: TypetwoPage
    },
    Servicii_Publice: {
        slug: "Servicii Publice",
        component: TypeonePage
    },
    Secretar: {
        slug: "Secretar",
        component: TypeonePage
    },

    Consiliu:{
        slug:"Consiliu Local",
        component: TypeonePage
    },
    Comisie_de_Specialitate: {
        slug: "Comisie de Specialitate",
        component: ComisideSpecialitate
    },
    Regulamente: {
        slug: "Comisie/Regulamente",
        component: TypeonePage
    },
    Lista_Consilierilor: {
        slug: "Lista Consilierilor",
        component: TypeonePage
    },

    Transparență:{
        slug:"Transparență",
        component: TypeonePage
    },
    Acte_Administrative: {
        slug: "Acte Administrative",
        component: TypeonePage
    },
    Regulamente: {
        slug: "Transparență/Regulamente",
        component: TypeonePage
    },
    Decizii: {
        slug: "Decizii",
        component: TypeonePage
    },
    Angajamente: {
        slug: "Angajamente",
        component: TypeonePage
    },
    
};

export default routes;
