import React, { useState } from 'react';
import FacebookNews from '../../assets/site_assets/FacebookNews.svg';
import twiterNews from '../../assets/site_assets/twiterNews.svg';
import telegram from '../../assets/site_assets/telegram.svg';
import Share from '../../assets/site_assets/Share.svg';
import Question from '../../assets/site_assets/question.svg';
import Yes from '../../assets/site_assets/Yes.svg';
import No from '../../assets/site_assets/No.svg';

import { 
    FacebookShareCount, 
    FacebookIcon, 
    FacebookShareButton, 
    TwitterIcon, 
    TwitterShareButton, 
    TelegramIcon, 
    TelegramShareButton 
} from 'react-share';

const Distribuie = ({ page_url }) => {
    const [activeButton, setActiveButton] = useState(null);

    const handleClick = (button) => {
        setActiveButton(button);
    };

    return (
        <div className='mt-[110px] px-4 md:px-0'>
            <div className="flex flex-col gap-[20px]">
                <h1 className='text-[24px] leading-[27px] text-center md:text-left'>DISTRIBUIE</h1>
                
                <div className="flex justify-center gap-[20px] md:justify-start">
                    {/* Facebook Share Button */}
                    <FacebookShareButton url={page_url}>
                        <img src={FacebookNews} alt="Facebook" className="w-8 h-8" />
                    </FacebookShareButton>
                    
                    {/* Twitter Share Button */}
                    <TwitterShareButton url={page_url}>
                        <img src={twiterNews} alt="Twitter" className="w-8 h-8" />
                    </TwitterShareButton>

                    {/* Telegram Share Button */}
                    <TelegramShareButton url={page_url}>
                        <img src={telegram} alt="Telegram" className="w-8 h-8" />
                    </TelegramShareButton>
                </div>

                {/* Feedback Section */}
                <div className="flex flex-col md:flex-row gap-[9px] w-full items-center">
                    <img className={Question} src={Question} alt="Question" />
                    <span className='flex flex-col md:flex-row justify-between w-full items-center'>
                        <div className="text-center md:text-left">
                            <p className='text-[16px] leading-[24px] font-normal opacity-[0.8]'>Au fost utile aceste informatii?</p>
                            <p className='font-light text-[12px] leading-[18px] tracking-[1px] opacity-[0.4]'>Raspunsul dvs. ne va ajuta sa imbunatatim acest site.</p>
                        </div>
                        <div className="flex gap-[10px] mt-4 md:mt-0">
                            <button
                                className={`flex gap-[6px] border rounded-full py-2 px-4 transition duration-300 
                                    ${activeButton === 'yes' ? 'bg-blue-500 text-white border-blue-500' : 'bg-white text-black border border-black border-opacity-[0.4] hover:bg-blue-500 hover:text-white'}`}
                                onClick={() => handleClick('yes')}
                            >
                                <img src={Yes} alt="Yes" />
                                <span>DA</span>
                            </button>
                            <button
                                className={`flex gap-[6px] border rounded-full py-2 px-4 transition duration-300 
                                    ${activeButton === 'no' ? 'bg-gray-300 text-white border-gray-300' : 'bg-white text-black border border-black border-opacity-[0.4] hover:bg-gray-300 hover:text-white'}`}
                                onClick={() => handleClick('no')}
                            >
                                <img src={No} alt="No" />
                                <span>NU</span>
                            </button>
                        </div>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default Distribuie;
