import { useEffect, useState, useContext } from "react";
import axios from "axios";
import UrlContext from "../contexts/globalContext";
import QueryString from "qs";
import { LanguageContext } from '../contexts/languageContext';

const useFetchImages = (limit = 4) => {
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const { languages, currentLangIndex } = useContext(LanguageContext);
    const apiUrl = useContext(UrlContext);
    const url = apiUrl[0];
    const apikey = apiUrl[1];

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); // Set loading to true before fetching

            if (!url) {
                setError(new Error("URL context is undefined"));
                setLoading(false);
                return;
            }

            const query = QueryString.stringify({
                locale: languages[currentLangIndex],
                populate: "*",
                sort: ['createdAt:desc'],
            });

            try {
                const response = await axios.get(
                    `${url}/api/noutati-anunturis?${query}`,
                    {
                        headers: {
                            Authorization: `Bearer ${apikey}`,
                        },
                    }
                );
                const data = response?.data?.data || [];

                if (data.length === 0) {
                    throw new Error("No images found");
                }

                setImages(data);
                setError(null); // Clear any previous errors
            } catch (err) {
                console.error("Error fetching images:", err);
                setError(err);
            } finally {
                setLoading(false); // Set loading to false after fetching
            }
        };

        fetchData();
    }, [url, languages, currentLangIndex]); // Ensure dependencies include languages

    return { images, loading, error, url };
};

export default useFetchImages;
