import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import QueryString from "qs";
import UrlContext from "../../contexts/globalContext";
import BurgerMenu from '../BurgerMenu/BurgeMenu';
import { LanguageContext } from "../../contexts/languageContext";
import LanguageSelector from "../LanguageSelector";
import { Link } from "react-router-dom";
import LinkArrow from '../../assets/site_assets/LinkArrow.svg';

const Header = () => {
    const links = [
        {
            title: "Acasă",
            sublinks: []
        },
        {
            title: "Floreni",
            sublinks: [
                "Statistici",
                "Activități",
                "Istoric",
                "Organizații Membre",
                "Așezare Geografică"
            ],
        },
        {
            title: "Primăria",
            sublinks: [
                "Primar",
                "Secretar",
                {
                    title: "Transparență",
                    sublinks: [
                        "Decizii",
                        "Angajamente",
                        "Acte administrative",
                    ]
                },
                "Servicii Publice",
                {
                    title: "Consiliu Local",
                    sublinks: [
                        "Lista Consilierilor",
                        "Comisie de Specialitate",
                    ]
                }
            ],
        },
        {
            title: "Turism",
            sublinks: []
        },
        {
            title: "Noutăți",
            sublinks: [],
        },
        {
            title: "Contact",
            sublinks: []
        },
    ];

    const [logo, setLogo] = useState("");
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [languageIcons, setLanguageIcons] = useState([]);
    const [hoveredLink, setHoveredLink] = useState(null); // State to track hovered link
    const [hoveredSubLink, setHoveredSubLink] = useState(null); // State to track hovered sublink

    const { languages, currentLangIndex } = useContext(LanguageContext);
    const urlApi = useContext(UrlContext);
    const url = urlApi[0];
    const apikey = urlApi[1];

    const query = QueryString.stringify({
        locale: languages[currentLangIndex],
        populate: "*",
    });

    useEffect(() => {
        axios
            .get(`${url}/api/bara-de-navigare?${query}`, {
                headers: {
                    Authorization: `Bearer ${apikey}`,
                },
            })
            .then((response) => response.data.data.attributes)
            .then((data) => {
                setLogo(data.Logo.data.attributes.url);
                setLanguageIcons(data.LimbaIconita.data);
            })
            .catch((error) => console.log(error));
    }, [currentLangIndex]);

    const toggleMenu = () => {
        setIsMenuOpen((prev) => !prev);
    };

    const slugify = (text) => text.toLowerCase().replace(/\s+/g, '-');

    return (
        <header className="bg-main py-8 xl:text-xl">
            <div className="container mx-auto flex justify-between items-center px-[16px] md:px-[98px] gap-2">
                {/* Logo */}
                <Link to="/">
                    {logo ? <img src={`${url}${logo}`} alt="Logo" className="w-24 md:w-32 h-10 object-cover" /> : ""}
                </Link>

                {/* Burger Menu (Mobile) */}
                <div className="md:hidden">
                    <BurgerMenu isOpen={isMenuOpen} toggleMenu={toggleMenu} links={links} />
                </div>

                {/* Links Container (Desktop) */}
                <nav className="hidden md:flex space-x-8">
                    {links.length > 0 ? (
                        links.map((link, index) => (
                            <div
                                key={index}
                                onMouseEnter={() => setHoveredLink(index)}
                                onMouseLeave={() => {
                                    setHoveredLink(null);
                                    setHoveredSubLink(null); // Reset sublink hover state
                                }}
                                className="relative"
                            >
                                {/* Link Container with flex to align text and arrow */}
                                <Link
                                    to={link.path ? link.path : `/${slugify(link.title)}`}
                                    className="text-white font-medium hover:text-gray-300 transition-colors duration-200 flex items-center gap-[6px]"
                                >
                                    {link.title}

                                    {/* Conditionally render LinkArrow for specific titles */}
                                    {(link.title === "Floreni" || link.title === "Primăria") && (
                                        <img src={LinkArrow} alt="" className={`inline-block transition-all duration-300 ${hoveredLink === index ? "rotate-180" : "rotate-0"}`} />
                                    )}
                                </Link>

                                {link.sublinks.length > 0 && hoveredLink === index && (
                                    <ul className="absolute left-0 bg-white text-black shadow-lg rounded-md z-40">
                                        {link.sublinks.map((sublink, subIndex) => (
                                            typeof sublink === 'string' ? (
                                                <li key={subIndex}>
                                                    <Link
                                                        to={`/${slugify(sublink)}`}
                                                        className="block px-4 text-nowrap py-2 hover:bg-gray-200 transition-colors duration-200 rounded-md"
                                                    >
                                                        {sublink}
                                                    </Link>
                                                </li>
                                            ) : (
                                                <li
                                                    key={subIndex}
                                                    onMouseEnter={() => setHoveredSubLink(subIndex)}
                                                    onMouseLeave={() => setHoveredSubLink(null)}
                                                    className="relative"
                                                >
                                                    <Link
                                                        to={`/${slugify(sublink.title)}`}
                                                        className="px-4 py-2 hover:bg-gray-200 transition-colors duration-200 flex items-center gap-2 rounded-md"
                                                    >
                                                        {sublink.title}

                                                    </Link>

                                                    {/* Show sublinks on hover */}
                                                    {hoveredSubLink === subIndex && sublink.sublinks.length > 0 && (
                                                        <ul className="absolute left-36 text-nowrap -mt-10 bg-gray-100 text-black shadow-lg rounded-md z-20">
                                                            {sublink.sublinks.map((subSubLink, subSubIndex) => (
                                                                <li key={subSubIndex}>
                                                                    <Link
                                                                        to={`/${slugify(subSubLink)}`}
                                                                        className="block px-4 py-2 hover:bg-gray-200 transition-colors duration-200 rounded-md"
                                                                    >
                                                                        {subSubLink}
                                                                    </Link>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                </li>
                                            )
                                        ))}
                                    </ul>
                                )}
                            </div>
                        ))
                    ) : (
                        <p className="text-white">No links</p>
                    )}
                </nav>


                {/* Language Selector */}
                <div className="hidden md:flex items-center space-x-3 max-w-[128px]">
                    <LanguageSelector />
                </div>
            </div>
        </header>
    );
};

export default Header;
