import React, { useEffect, useRef, useState } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import Left from '../../assets/site_assets/Left.svg';
import Right from '../../assets/site_assets/Rightsvg.svg';
import Play from '../../assets/site_assets/play.svg';
import Fullscreen from '../../assets/site_assets/fullscreen.svg';
import BottomArrow from '../../assets/site_assets/BottomArrow.svg';
import Plus from '../../assets/site_assets/Plus.svg';
import Delete from '../../assets/site_assets/Delete.svg';
import Back from '../../assets/site_assets/Back.svg';
import '@splidejs/react-splide/css';

import Pause from '../../assets/site_assets/pause-button.png';
import Loader from '../Loader/Loader';

const PhotoPlayer = ({ media, url }) => {
  const splideRef = useRef(null);
  const [images, setImages] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [autoplayInterval, setAutoplayInterval] = useState(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [showThumbnails, setShowThumbnails] = useState(false); // Initially false, so the thumbnails are hidden
  const [isLoading, setIsLoading] = useState(true);
  const [isDragging, setIsDragging] = useState(false);
  const [lastMousePos, setLastMousePos] = useState({ x: 0, y: 0 });
  const [imgTransform, setImgTransform] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (media && media.length > 0) {
      setImages(media);
      setIsLoading(false);
    } else {
      setImages([]);
      console.error("No media provided");
    }
  }, [url, media]);

  const togglePlay = () => {
    if (isPlaying) {
      clearInterval(autoplayInterval);
      setIsPlaying(false);
    } else {
      const interval = setInterval(() => {
        splideRef.current?.splide?.go('>');
      }, 1000);
      setAutoplayInterval(interval);
      setIsPlaying(true);
    }
  };

  const zoomIn = () => setZoomLevel((prev) => prev + 0.2);
  const zoomOut = () => setZoomLevel((prev) => Math.max(prev - 0.2, 1));
  const resetZoom = () => setZoomLevel(1);

  const goFullscreen = () => {
    const element = document.getElementById('slider');
    if (element) {
      if (!isFullscreen) {
        element.requestFullscreen()
          .then(() => setIsFullscreen(true))
          .catch((err) => console.error("Error attempting to enable full-screen mode:", err));
      } else {
        exitFullscreen();
      }
    }
  };

  const exitFullscreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen().then(() => {
        setIsFullscreen(false);
      }).catch((err) => {
        console.error("Error attempting to exit full-screen mode:", err);
      });
    } else {
      setIsFullscreen(false);
    }
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  const handleThumbnailClick = (index) => {
    splideRef.current?.splide?.go(index);
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setLastMousePos({ x: e.clientX, y: e.clientY });
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const deltaX = e.clientX - lastMousePos.x;
      const deltaY = e.clientY - lastMousePos.y;
      setImgTransform(prev => ({
        x: prev.x + deltaX,
        y: prev.y + deltaY,
      }));
      setLastMousePos({ x: e.clientX, y: e.clientY });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };

  return (
    <div id="slider" className={`relative bg-white mt-[38px] flex w-full ${isFullscreen ? "w-[100vw] h-[100vh] z-20" : 'max-h-[523px] sm:max-h-[400px] md:max-h-[450px] lg:max-h-[523px]'}`}>
      <main className="relative flex flex-col w-full">
        {isLoading ? (
          <div className="flex justify-center items-center h-full">
            <Loader size={30} />
          </div>
        ) : images.length > 0 ? (
          <>
            <div className="">
              <div className="absolute flex flex-col gap-2 ml-2 mt-2 z-10">
                <button onClick={zoomIn} className="flex items-center">
                  <img src={Plus} alt="Zoom In" className="w-5 h-5 sm:w-4 sm:h-4" />
                </button>
                <button onClick={zoomOut} className="flex items-center">
                  <img src={Delete} alt="Zoom Out" className="w-5 h-5 sm:w-4 sm:h-4" />
                </button>
                <button onClick={resetZoom} className="flex items-center">
                  <img src={Back} alt="Reset Zoom" className="w-5 h-5 sm:w-4 sm:h-4" />
                </button>
              </div>

              <Splide ref={splideRef} options={{ type: 'loop', perPage: 1, pagination: false, arrows: false }}>
                {images.map((image, index) => {
                  const imageUrl = image?.attributes?.url ? `${url}${image.attributes.url}` : image;

                  return (
                    <SplideSlide key={index}>
                      <div
                        className={`w-full h-full flex items-center justify-center overflow-hidden ${isFullscreen ? "h-screen" : ''}`}
                        onMouseDown={handleMouseDown}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUp}
                        onMouseLeave={handleMouseLeave}
                      >
                        <img
                          src={imageUrl}
                          alt={`Slide ${index + 1}`}
                          className={`m-auto w-full h-full object-contain max-h-[523px] sm:max-h-[300px] md:max-h-[400px]`}
                          style={{
                            transform: `scale(${zoomLevel}) translate(${imgTransform.x}px, ${imgTransform.y}px)`,
                            transformOrigin: 'center',
                            transition: 'transform 0.3s ease',
                          }}
                        />
                      </div>
                    </SplideSlide>
                  );
                })}
              </Splide>

              <button className="bg-gray-200 hover:text-white hover:bg-blue-500 p-1 md:p-3 rounded-full absolute left-4 top-1/2 -translate-y-1/2 z-10" onClick={() => splideRef.current?.splide?.go('<')}>
                <img src={Left} alt="Left Arrow" className="md:w-6 w-5 md:h-6 h-5" />
              </button>

              <button className="bg-gray-200 hover:text-white hover:bg-blue-500 p-1 md:p-3 rounded-full absolute right-4 top-1/2 -translate-y-1/2 z-10" onClick={() => splideRef.current?.splide?.go('>')}>
                <img src={Right} alt="Right Arrow" className="md:w-6 w-5 md:h-6 h-5" />
              </button>

              <div className={`absolute right-0 bottom-2 flex flex-row justify-end gap-6 mr-[15px] z-30 ${isFullscreen ? "bottom-5 " : ''}`}>
                <button onClick={togglePlay} className="flex items-center">
                  <img src={isPlaying ? Pause : Play} alt="Play" className="w-5 h-5 hover:cursor-pointer" />
                </button>
                <button onClick={goFullscreen} className="flex items-center" id='full-screen-btn'>
                  <img src={Fullscreen} alt="Fullscreen" className="w-5 h-5" />
                </button>
              </div>

              {/* Button to toggle thumbnails, keeping the arrow where it was */}
              {/* Button to toggle thumbnails, keeping the arrow in the left bottom corner */}
              <button
                onClick={() => setShowThumbnails(!showThumbnails)}
                className="absolute bottom-2 left-2 z-10 flex justify-center"
                style={{ transform: `rotate(${showThumbnails ? '180deg' : '0deg'})`, transition: 'transform 0.3s ease' }}
              >
                <img src={BottomArrow} alt="Bottom Arrow" className="" />
              </button>


              {/* Thumbnails */}
              <div
                className={`absolute w-full bg-white overflow-hidden overflow-x-auto flex justify-center transition-all duration-500 ease-in-out ${showThumbnails ? 'h-[100px]' : 'h-0'}`}
              >
                {images && images.map((image, index) => {
                  const thumbnailUrl = image?.attributes?.url ? `${url}${image.attributes.url}` : image;

                  return (
                    <img
                      key={index}
                      src={thumbnailUrl}
                      alt={`Thumbnail ${index + 1}`}
                      className={`cursor-pointer border ${index === splideRef.current?.splide?.index ? 'border-blue-500' : 'border-transparent'} w-20 h-20 object-cover`}
                      onClick={() => handleThumbnailClick(index)}
                    />
                  );
                })}
              </div>
            </div>
          </>
        ) : null}
      </main>
    </div>
  );
};

export default PhotoPlayer;
