// react-spinners
import { SyncLoader } from "react-spinners";
const Loader = ({ size, classes }) => {
    return (
        <div className={`${classes} h-[60vh] flex justify-center items-center w-full`}>
            <p className="animate-pulse animate-infinite text-4xl animate-duration-[900ms]">DOT</p>

        </div>
    )
}

export default Loader