import QueryString from 'qs';
import UrlContext from '../contexts/globalContext';
import axios from 'axios';
import React, { useEffect, useState, useContext } from 'react';
import { LanguageContext } from '../contexts/languageContext';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';

import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import NoutatiSmallSideBar from '../components/NoutatiSmallSideBar/NoutatiSmallSideBar';
import NoutatiRecenteSidebar from '../components/NoutatiRecenteSidebar/NoutatiRecenteSidebar';
import Loader from '../components/Loader/Loader';

const ComisideSpecialitate = () => {
    const committees = [
        {
          title: "COMISIA BUGET ȘI FINANȚE:",
          members: [
            { name: "Fortuna Vasile", job: "Preşedintele comisiei" },
            { name: "Ciorba Victoria", job: "Secretar" },
            { name: "Gribineţ Ludmila", job: "Membru" },
            { name: "Tulba Veaceslav", job: "Membru" },
            { name: "Botnaru Igor", job: "Membru" },
          ],
        },
        {
          title: "COMISIA DE DEZVOLTARE SOCIAL – ECONOMICĂ",
          members: [
            { name: "Morozan Victor", job: "Preşedintele comisiei" },
            { name: "Mocanu Angela", job: "Secretar" },
            { name: "Morari Valentina", job: "Membru" },
            { name: "Begal Victor", job: "Membru" },
            { name: "Hîncu Igor", job: "Membru" },
          ],
        },
        {
          title: "COMISIA EDUCAȚIE, CULTURĂ, SPORT ȘI PROBLEME SOCIALE",
          members: [
            { name: "Tulba Veaceslav", job: "Preşedintele comisiei" },
            { name: "Paladii Victoria", job: "Secretar" },
            { name: "Doloşcan Valeriu", job: "Membru" },
            { name: "David Alexandru", job: "Membru" },
            { name: "Hîncu Igor", job: "Membru" },
          ],
        },
    ];

    return (
        <>
            <Header />
            <div className="container mx-auto px-4 py-8">
                <div className="flex flex-col space-y-8">
                    
                    {committees.map((committee, index) => (
                        <div key={index} className="bg-white p-6 shadow-md rounded-md">
                            <h2 className="text-center lg:text-left text-2xl font-semibold mb-4 text-gray-800 border-b pb-2">
                                {committee.title}
                            </h2>
                            <table className="min-w-full border-collapse border border-gray-300">
                                <thead>
                                    <tr className="bg-gray-100 text-gray-700">
                                        <th className="text-center md:text-left px-6 py-3 font-medium text-sm border border-gray-300">Name</th>
                                        <th className="text-center md:text-left px-6 py-3 font-medium text-sm border border-gray-300">Job/Title</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {committee.members.map((member, idx) => (
                                        <tr key={idx} className={`hover:bg-gray-50 ${idx % 2 === 1 ? 'bg-gray-50' : 'bg-white'}`}>
                                            <td className="text-center md:text-left px-6 py-3 text-gray-800 border border-gray-300">{member.name}</td>
                                            <td className="text-center md:text-left px-6 py-3 text-gray-800 border border-gray-300">{member.job}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ))}

                </div>
            </div>
            <Footer />
        </>
    );
};

export default ComisideSpecialitate;
