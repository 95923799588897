import HomePage from './pages/Home/HomePage';
import UrlContext from './contexts/globalContext';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import React, { useEffect } from 'react';
import { LanguageProvider } from './contexts/languageContext';
import routes from './Routes';
import DynamicPage from './DynamicPage';

function App() {

  const slugify = (text) => text.toLowerCase().replace(/\s+/g, '-');

  return (
    <BrowserRouter>
      <div className="App font-sharp-grotesk bg-[#F7F7F7]">
        <UrlContext.Provider value={[
          'https://strapi-floreni-u23253.vm.elestio.app',
          'cd42085522c9d26d1dc06044fadefe391dbb4489c99ed33ea09681d7eee0f936cac9277e5e9a8046e2e1a96cceda02d0958ac481d88cff0845181f3ee62b5b8e4f98cfd052472a7ffee2c67acd9f22bfdcfe0d944e8d338ba6d2178596117d689cece021499eafcd6760a109095dd2b539b511e37289df94db6edfca528644ab'
        ]}>
          <LanguageProvider>
            <Routes>
              <Route path="/" element={<HomePage />} />
              {Object.values(routes).map((route) => (
                <Route
                  key={route.slug}
                  path={`/${slugify(route.slug)}`}
                  element={<route.component slug={route.slug} />}
                />
              ))}
              <Route path="/:slug" element={<DynamicPage />} />
            </Routes>
          </LanguageProvider>
        </UrlContext.Provider>
      </div>
    </BrowserRouter>
  );
}

export default App;
