import React, { useEffect, useState, useContext } from 'react';
import FooterArrow from '../../assets/site_assets/FooterArrow.svg';
import QueryString from 'qs';
import UrlContext from '../../contexts/globalContext';
import axios from 'axios';
import { LanguageContext } from '../../contexts/languageContext';

const handleFooterRendering = (data) => {
  if (data.Tel) {
    return (
      <>
        <div className="flex flex-row gap-[12px]">
          <span className='font-light leading-[21px] tracking-[1.21px]'>{data.Tel}</span>
          <p className='font-normal leading-[24px]'>{data.Telline1}</p>
        </div>
        <span className='font-normal leading-[24px]'>{data.email}</span>
      </>
    );
  }

  if (data.Line2) {
    return (
      <div className="flex flex-col font-normal leading-[24px]">
        <span>{data.Line1}</span>
        <span>{data.Line2}</span>
      </div>
    );
  }

  if (data.Line1) {
    return <a href='https://maps.app.goo.gl/YW2DQLiQC9LwZo8x7' className="lg:mt-[24px] font-normal leading-[24px]">{data.Line1}</a>;
  }

  return '';
};

const Footer = () => {
  const { languages, currentLangIndex } = useContext(LanguageContext);
  const apiUrl = useContext(UrlContext);
  const url = apiUrl[0];
  const apikey = apiUrl[1];

  const [logo, setLogo] = useState('');
  const [title, setTitle] = useState('');
  const [newsLetterTitle, setNewsLetterTitle] = useState('');
  const [credentials, setCredentials] = useState('');
  const [blocks, setBlocks] = useState([]);
  const [socialLinks, setSocialLinks] = useState([]);

  useEffect(() => {
    const query = QueryString.stringify({
      locale: languages[currentLangIndex],
      populate: {
        footer: {
          populate: {
            SocialLink: {
              populate: "*"
            },
            Block: {
              populate: "*"
            },
            Logo: {
              populate: "*"
            }
          }
        }
      }
    });

    axios.get(`${url}/api/main-page?${query}`, {
      headers: {
        Authorization: `Bearer ${apikey}`
      }
    })
      .then(response => {
        setLogo(response.data.data.attributes.footer.Logo.data.attributes.url);
        setTitle(response.data.data.attributes.footer.Titlu);
        setNewsLetterTitle(response.data.data.attributes.footer.NewsletterTitlu);
        setCredentials(response.data.data.attributes.footer.Credentials);
        setBlocks(response.data.data.attributes.footer.Block);
        setSocialLinks(response.data.data.attributes.footer.SocialLink);
      })
      .catch(err => {
        console.error("Error fetching footer data:", err);
      });
  }, [url, currentLangIndex]);

  return (
    <div className="bg-[#000B15] px-[16px] md:px-[100px] mt-[100px] pt-[60px] pb-[44px] text-[#FEFEFE] xl:text-xl w-full">
      {/* Logo and Title */}
      <div className="flex flex-col gap-[24px] items-start">
        <img src={logo ? `${url}${logo}` : ""} alt="Footer Logo" className='object-contain h-auto w-auto' />
      </div>

      {/* Main Content */}
      <div className="flex flex-col lg:flex-row justify-between gap-[70px] mt-[60px]">
        {/* Newsletter Section */}
        <div className="flex flex-col gap-[24px] w-full lg:w-[270px]">
          <h1 className="text-[16px] xl:text-[22px]">{newsLetterTitle || 'Se incarca...'}</h1>
          <form className="flex items-center gap-[10px]">
            <input
              type="email"
              placeholder="Email"
              id='email'
              autoComplete='off'
              className="w-full py-2 bg-transparent border-b-2 border-x-transparent placeholder-opacity-[0.6] invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:outline-none"
            />
            <button className="bg-white rounded-full px-[10px] py-[8px]">
              <img src={FooterArrow} alt="Submit" />
            </button>
          </form>
          <p className="opacity-[0.6] text-[14px] xl:text-[20px] mt-[10px]">{credentials || 'Se incarca...'}</p>
        </div>

        {/* Contact Information */}
        <div className="flex flex-col gap-[24px]">
          {/* Linia Verde Section */}
          <div className="flex flex-col gap-[10px] opacity-[0.8]">
            <h2 className="text-[16px] xl:text-[22px] font-bold">{blocks[0]?.Titlu || ""}</h2>
            {blocks[0] ? handleFooterRendering(blocks[0]) : "Se incarca..."}
          </div>

          {/* Contact Section */}
          <div className="flex flex-col gap-[10px] opacity-[0.8]">
            <h3 className="text-[16px] xl:text-[22px] font-bold">{blocks[1]?.Titlu || ""}</h3>
            {blocks[1] ? handleFooterRendering(blocks[1]) : "Se incarca..."}
          </div>
        </div>

        {/* Press and Program Information */}
        <div className="flex flex-col gap-[24px]">
          {/* Press Section */}
          <div className="flex flex-col gap-[10px] opacity-[0.8]">
            <h2 className="text-[16px] xl:text-[22px] font-bold">{blocks[2]?.Titlu || ""}</h2>
            {blocks[2] ? handleFooterRendering(blocks[2]) : "Se incarca..."}
          </div>

          {/* Program Section */}
          <div className="flex flex-col gap-[10px] opacity-[0.8]">
            <h3 className="text-[16px] xl:text-[22px] font-bold">{blocks[3]?.Titlu || ""}</h3>
            {blocks[3] ? handleFooterRendering(blocks[3]) : "Se incarca..."}
          </div>
        </div>

        {/* Address and Social Links */}
        <div className="flex flex-col gap-[24px] justify-between">
          {/* Address Section */}
          <div className="flex flex-col gap-[10px] opacity-[0.8]">
            <h4 className="text-[16px] xl:text-[22px] font-bold">{blocks[4]?.Titlu || ""}</h4>
            {blocks[4] ? handleFooterRendering(blocks[4]) : "Se incarca..."}
          </div>

          {/* Social Icons */}
          <div className="flex flex-row gap-[20px] items-center">
            {socialLinks.length > 0 ? socialLinks.map((link, index) => (
              <a href={link.Url || "#"} key={index} target="_blank" rel="noopener noreferrer">
                <img src={`${url}${link.Icon?.data[0]?.attributes?.url || ""}`} alt={link.Icon?.data[0]?.attributes?.name || 'Social Icon'} />
              </a>
            )) : "Se incarca..."}
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center mt-9">
        <span className='opacity-60 text-sm md:text-xl'>
          Site Susținut de
          <a
            href="https://dotteam.md"
            className='ml-1 font-semibold text-blue-600 hover:text-blue-800 transition-opacity duration-300 hover:opacity-100'
          >
            DOT
          </a>
        </span>
      </div>

    </div>
  );
};

export default Footer;