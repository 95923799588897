import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
            <h1 className="text-6xl font-bold text-red-600">404</h1>
            <h2 className="text-2xl mt-4">Pagina inexistenta</h2>
            <p className="mt-2 text-gray-700">Ne cerem scuze, pagina pe care o cauti nu exista.</p>
            <Link to="/" className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition">
                Acasa
            </Link>
        </div>
    );
};

export default NotFoundPage;
