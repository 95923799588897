import QueryString from 'qs';
import UrlContext from '../contexts/globalContext';
import axios from 'axios';
import React, { useEffect, useState, useContext, useLayoutEffect } from 'react';
import { LanguageContext } from '../contexts/languageContext';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import { useLocation } from 'react-router-dom';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import NoutatiSmallSideBar from '../components/NoutatiSmallSideBar/NoutatiSmallSideBar';
import NoutatiRecenteSidebar from '../components/NoutatiRecenteSidebar/NoutatiRecenteSidebar';
import ViewIcon from '../assets/site_assets/lets-icons_view-light.svg';
import Print from '../assets/site_assets/print.svg';
import PhotoPlayer from '../components/MediaPlayer/PhotoPlayer';
import Distribuie from '../components/Distribuie/Distribuie';

import Loader from '../components/Loader/Loader';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const Propuneri = () => {
    const location = useLocation();

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);
    const slugify = (text) => text.toLowerCase().replace(/\s+/g, '-');

    const { titlu } = useParams();
    const [title, setTitle] = useState('');
    const [content, setContent] = useState([]);
    const [createdAt, setCreatedAt] = useState('');
    const [banner, setBanner] = useState('');
    const [media, setMedia] = useState([]);


    const { languages, currentLangIndex } = useContext(LanguageContext);
    const apiUrl = useContext(UrlContext);
    const url = apiUrl[0];
    const apikey = apiUrl[1];

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {

            try {
                const query = QueryString.stringify({
                    locale: languages[currentLangIndex],
                    populate: {
                        propunere: {
                            populate: "*"
                        }
                    },
                });

                // Fetch data for the specific page using its ID
                const response = await axios.get(`${url}/api/propuneri-container?${query}`, {
                    headers: {
                        Authorization: `Bearer ${apikey}`
                    }
                });

                const rawPages = response.data.data.attributes.propunere;

                if (rawPages.length === 0) {
                    setLoading(false);
                    return;
                }
                rawPages.forEach(page => {
                    if (slugify(page.Titlu) === slugify(titlu)) {
                        setTitle(page.Titlu);
                        setContent(page.Descriere);
                        setCreatedAt(page.DataCrearii);
                        setBanner(page.Banner.data.attributes.url);
                        setMedia(page.Media.data);
                    }
                });

                // Check if title or content are still undefined
                if (!title || !content) {
                    setLoading(false);
                    return;
                }

            } catch (err) {
                console.error("Error fetching page data:", err);
                setError("Failed to load data.");
            } finally {
                setLoading(false); // Ensure loading state is set to false
            }
        };

        fetchData();
    }, [url, currentLangIndex, titlu]); // Add titlu to the dependencies


    if (error) {
        return <div>{error}</div>;
    }

    let formattedDate = '';

    if (createdAt) {
        const date = new Date(createdAt);

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        formattedDate = `${day}.${month}.${year}`;
    }


    return (
        <>
            {loading && (
                <div className="fixed inset-0 bg-white flex justify-center items-center z-50">
                    <Loader size={30} />
                </div>
            )}
            <Header />
            <div className="container mx-auto">
                <main className="px-4 md:px-8 lg:px-16 pt-8 lg:pb-12 grid grid-cols-1 lg:grid-cols-3 gap-8">
                    {/* Left Sidebar Column */}
                    <div className="flex flex-col-reverse lg:flex-col md:col-span-1 row-start-2 lg:row-start-auto w-full">
                        <NoutatiSmallSideBar />
                        <NoutatiRecenteSidebar />
                    </div>

                    {/* Main Content Column */}
                    {content ? (
                        <div className="md:col-span-2 relative">
                            <div className="">
                                <span className='flex gap-[12px]'>
                                    <Link className='opacity-[0.4]' to={'/'}>Acasa</Link>
                                    /
                                    <p>{titlu}</p>
                                </span>
                            </div>
                            {/* Main image */}
                            <div className="relative mt-[28px]">
                                <img
                                    className="w-full h-auto"
                                    src={`${url}${banner}`}
                                    alt=""
                                />
                                {/* White box with text over the image */}
                                <div className="container absolute px-7 -bottom-8 md:-bottom-10 left-0 w-full z-10">
                                    <div className="bg-white w-full p-6">
                                        <p className="text-wrap text-2xl md:text-3xl font-medium leading-snug">
                                            {title}
                                        </p>
                                        <div className="flex sm:justify-between flex-col sm:flex-row mt-5 items-start gap-2">
                                            <span className="text-[#161616CC] flex gap-4 opacity-80 font-light tracking-wide leading-5 text-sm md:text-base">
                                                <data value="$">{formattedDate}</data>
                                                <span className="flex gap-2">
                                                    <img className="w-5 h-5" src={ViewIcon} alt="" />
                                                    <p>50</p>
                                                </span>
                                            </span>

                                            <button onClick={()=>window.print()} className="flex gap-2 items-center">
                                                <img src={Print} alt="" />
                                                <span className="text-[#4F7DF2] text-sm md:text-base">Imprimare</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Paragraph below the image and the white box */}
                            <p className='text-sm md:text-base text-[#161616CC] opacity-80 mt-20 md:mt-20 mx-4 md:mx-6 lg:mx-10 text-pretty prose max-w-none'>
                                {<BlocksRenderer content={content} />}
                            </p>
                            {
                                media && media.length > 0 ? (
                                    <PhotoPlayer media={media} url={url} />

                                ) : null
                            }
                            <div className='hidden lg:block'>
                                <Distribuie />
                            </div>
                        </div>
                    ) : (
                        <div className="flex justify-center items-center min-h-[300px] w-full col-span-2">
                            <Loader size={30} />
                        </div>
                    )}
                </main>
                <div className='block lg:hidden px-8 mt-[-4rem]'>
                    <Distribuie />
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Propuneri;
