import React, { useRef } from 'react';

const TagsCard = ({ icon, url, text }) => {


    return (
        <div
            className={`bg-white !important flex 2xl:flex-row flex-col py-2 px-2 items-center justify-center gap-1.5 rounded-md 
            w-full hover:cursor-pointer hover:bg-main transition-all transform text-[#4F7DF2] group z-20 h-full`}
        >
            <img
                src={`${url}${icon}`}
                alt={text}
                className='w-8 h-8 transition-all duration-300 group-hover:filter group-hover:brightness-0 group-hover:invert'
            />
            <span className='text-sm leading-6  text-center md:text-start flex-grow overflow-hidden transition-all duration-300 group-hover:filter group-hover:brightness-0 group-hover:invert'>
                {text}
            </span>
        </div>
    );
};

export default TagsCard;
