import React, { useState, useEffect, useContext } from 'react';
import TagsCard from '../TagsCard/TagsCard';
import { Link } from 'react-router-dom';

// for fetchig
import { LanguageContext } from '../../contexts/languageContext';
import UrlContext from '../../contexts/globalContext';
import QueryString from 'qs';
import axios from 'axios';
const CardContainer = () => {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [tags, setTags] = useState([]);

    const { languages, currentLangIndex } = useContext(LanguageContext);
    const apiUrl = useContext(UrlContext);
    const url = apiUrl[0];
    const apikey = apiUrl[1];

    useEffect(() => {
        const query = QueryString.stringify({
            locale: languages[currentLangIndex],
            populate: {
                CautataInformatie: {
                    populate: "*"
                }
            }
        });

        axios.get(`${url}/api/main-page?${query}`,
            {
                headers: {
                    Authorization: `Bearer ${apikey}`
                }
            }

        )
            .then(response => {
                setTags(response.data.data.attributes.CautataInformatie);
                setLoading(false);
            })
            .catch(err => {
                console.error("Error fetching images:", err);
                setError(err);
                setLoading(false);
            });
    }, [url, currentLangIndex]);

    // Function to slugify the link names (e.g., "Formulare cereri" => "formulare-cereri")
    const slugify = (text) => text.toLowerCase().replace(/\s+/g, '-');
    return (
        <>
            {
                tags.map((tag, index) => (
                    <Link className='flex flex-col w-full' to={`/${slugify(tag.Titlu)}`} key={index}>
                        <TagsCard icon={tag.Icon.data.attributes.url} url={url} text={tag.Titlu} tag={tag} />
                    </Link>
                ))
            }
        </>
    )
}

export default CardContainer
