import React, { useEffect, useLayoutEffect, useState, Suspense } from "react";
import { useLocation } from "react-router-dom";
import Loader from "../../components/Loader/Loader";


const Header = React.lazy(() => import("../../components/Header/Header"));
const CarouselTop = React.lazy(() => import("../../components/CarouselTop/CarouselTop"));
const Statistics = React.lazy(() => import("../../components/Statisctics/Statistics"));
const MostAskedQContainer = React.lazy(() => import("../../components/MostAskedQContainer/MostAskedQContainer"));
const BannerContainer = React.lazy(() => import("../../components/BannerContainer/BannerContainer"));
const News = React.lazy(() => import("../../components/News/News"));
const BannerSlider = React.lazy(() => import("../../components/BannerSlider/BannerSlider"));
const NewsAnnouncements = React.lazy(() => import("../../components/NewsAnnouncements/NewsAnnouncements"));
const ConducemImpreunaContainer = React.lazy(() => import("../../components/ConducemImpreunaContainer/ConducemImpreunaContainer"));
const Footer = React.lazy(() => import("../../components/Footer/Footer"));

const HomePage = () => {
    const location = useLocation();
    const [loading, setLoading] = useState(true); // Loading state

    // Scroll to top when URL changes
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    // Simulate loading of all components
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false); // Set loading to false after a delay
        }, 1000); // Adjust loading time based on your data fetching logic

        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            {loading && (
                <div className="fixed inset-0 bg-white flex justify-center items-center z-50">
                    <Loader size={30} />
                </div>
            )}

            <Suspense fallback={<div style={{ height: '100px' }}>Loading Header...</div>}>
                <Header />
            </Suspense>

            <Suspense fallback={<div style={{ height: '400px' }}>Se incarca caruselul...</div>}>
                <CarouselTop />
            </Suspense>

            <div className="container mx-auto">
                <Suspense fallback={<div style={{ height: '200px' }}>Se incarca staisticile...</div>}>
                    <Statistics />
                </Suspense>

                <Suspense fallback={<div style={{ height: '300px' }}>Se incarca sectiunea cu cele mai frecvente intrebari...</div>}>
                    <MostAskedQContainer />
                </Suspense>

                <Suspense fallback={<div style={{ height: '400px' }}>Se incarca banerul...</div>}>
                    <BannerContainer />
                </Suspense>
                <Suspense fallback={<div style={{ height: '400px' }}>Se incarca noutatile...</div>}>
                    <News />
                </Suspense>
                <Suspense fallback={<div style={{ height: '400px' }}>Se incarca bannerul...</div>}>
                    <BannerSlider />
                </Suspense>
                <Suspense fallback={<div style={{ height: '400px' }}>Se incarca noutatile...</div>}>
                    <NewsAnnouncements />
                </Suspense>
                <Suspense fallback={<div style={{ height: '400px' }}>Se incarca sectiunea conducem impreuna ...</div>}>
                    <ConducemImpreunaContainer />
                </Suspense>
            </div>

            <Suspense fallback={<Loader size={30} />}>
                <Footer />
            </Suspense>
        </>
    );
};

export default HomePage;
