import React from 'react';
import LanguageSelector from '../LanguageSelector';

const BurgerMenu = ({ isOpen, toggleMenu, links }) => {
  return (
    <div className="relative">
      {/* Burger Icon */}
      <button
        onClick={toggleMenu}
        className="text-3xl md:hidden focus:outline-none text-white"
        aria-label="Toggle menu"
      >
        {isOpen ? '✖' : '☰'} {/* Simple text for menu icon */}
      </button>

      {/* Background Blur Overlay */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-40"
          onClick={toggleMenu} // Closes the menu when clicking outside
        ></div>
      )}

      {/* Menu Overlay */}
      <div
        className={`fixed inset-y-0 left-0 transform bg-main rounded-md text-white z-50 transition-transform duration-300 ease-in-out w-[50%] ${isOpen ? 'translate-x-0' : '-translate-x-full'
          }`}
      >
        <ul className="flex flex-col items-center p-4">
          {links.map((link,index) => (
            <li key={index} className="p-2 w-full border-b-2">
              <a href={link.title} className="hover:text-blue-400 transition duration-300" onClick={toggleMenu}>
                {link.title}
              </a>
            </li>
          ))}
        </ul>
        <div className="flex justify-start px-4 items-center gap-4">
        <LanguageSelector />

        </div>
      </div>
    </div>
  );
};

export default BurgerMenu;
