import React from 'react';
import CardContainer from '../MostAskedQContainer/CardContainer';

const NoutatiSmallSideBar = () => {
    return (
        <div className='flex flex-col gap-3 overflow-hidden'>

            {/* CardContainer */}
            <div className="mt-4 sm:mt-6 lg:mt-8 grid grid-cols-3 md:grid-cols-2 gap-4">
                <CardContainer />
            </div>
        </div>
    );
};

export default NoutatiSmallSideBar;
