import QueryString from 'qs';
import UrlContext from '../../contexts/globalContext';
import axios from 'axios';
import React, { useEffect, useLayoutEffect, useState, useContext, useMemo } from 'react';
import { LanguageContext } from '../../contexts/languageContext';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Loader from '../../components/Loader/Loader'; // Import your loader component
import PhotoPlayer from '../../components/MediaPlayer/PhotoPlayer';

const TypetwoPage = ({ slug }) => {
    const [title, setTitle] = useState('');
    const [banner, setBanner] = useState('');
    const [biography, setBiography] = useState([]);
    const [mainContent, setMainContent] = useState('');
    const [mediaUrls, setMediaUrls] = useState([]);
    const [cards, setCards] = useState([]);

    const [direction, setDirection] = useState(false);
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null);

    const result = slug.includes("/") ? slug.split("/").pop() : slug;

    const { languages, currentLangIndex } = useContext(LanguageContext);
    const apiUrl = useContext(UrlContext);
    const url = apiUrl[0];
    const apikey = apiUrl[1];

    // Memoize query construction to prevent unnecessary recalculations
    const query = useMemo(() => QueryString.stringify({
        locale: languages[currentLangIndex],
        filters: {
            Titlu: { $eq: result },
        },
        populate: {
            Card2: {
                populate: {
                    BannerSecundar: {
                        populate: "*"
                    }
                }
            },
            Media: {
                populate: "*"
            },
            Banner: {
                populate: "*"
            }
        },
    }), [languages, currentLangIndex, result]);

    // Scroll to top when slug changes
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [slug]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);

            try {
                const response = await axios.get(`${url}/api/template2s?${query}`, {
                    headers: {
                        Authorization: `Bearer ${apikey}`,
                    },
                });

                if (response.data.data && response.data.data.length > 0) {
                    const { Titlu, Banner, Card2, TextulPrincipal, Media, TitluVizual } = response.data.data[0].attributes;
                    setTitle(TitluVizual);
                    setBanner(Banner?.data?.attributes?.url);
                    setCards(Card2 || []);
                    setBiography(Card2?.map(item => item.Biografie) || []);
                    setDirection(Card2?.map(item => item.DirectiaStangaDreapta));
                    setMainContent(TextulPrincipal);
                    setMediaUrls(Media?.data?.map(item => item));
                } else {
                    setError("No data found.");
                }
            } catch (err) {
                if (err.response) {
                    setError(`Error: ${err.response.status} - ${err.response.data.message || 'An error occurred.'}`);
                } else if (err.request) {
                    setError("Network error. Please check your connection.");
                } else {
                    setError("An unexpected error occurred.");
                }
            }
        };

        fetchData();
    }, [url, query, apikey]);


    useEffect(() => {

        const timer = setTimeout(() => {
            setLoading(false); // Set loading to false after a delay (or once your data is loaded)
        }, 1000); // Change this to your loading time or use data fetching logic

        return () => clearTimeout(timer);
    }, [slug, title]);

    return (
        <>
            {/* Display Loader as overlay when loading */}
            {loading && (
                <div className="fixed inset-0 bg-white flex justify-center items-center z-50">
                    <Loader size={30} />
                </div>
            )}

            <Header />



            <img className="h-[50vh] w-full object-cover" src={`${url}${banner}`} alt={title} />
            <main className="container mx-auto px-4 py-8">
                <article className="bg-white p-4 md:p-8 rounded-md shadow-md">


                    {/* Cards with banners and biographies */}
                    {cards.length > 0 ? cards.map((card, index) => (
                        <div
                            key={index}
                            className={`flex ${direction[index] ? 'flex-col md:flex-row' : 'flex-col md:flex-row-reverse'} justify-center items-start relative my-20 p-6 gap-8`}

                        >
                            <img
                                src={`${url}${card.BannerSecundar?.data?.attributes?.url}`}
                                alt={title}
                                className="md:h-96 h-auto w-auto md:max-w-[384px] rounded-md shadow-md z-20 object-cover"
                            />
                            <div className="flex-1 z-20 pl-4">
                                <div className="text-base md:text-lg leading-relaxed text-gray-700 prose max-w-none">
                                    {card.Biografie ? <BlocksRenderer content={card.Biografie} /> : ""}
                                </div>
                            </div>
                            <div className="absolute inset-0 shadow-2xl z-10 rounded-md"></div>
                        </div>
                    )) : (
                        <p className="text-gray-500">No banners available.</p>
                    )}


                    <section>
                        <div className="text-base md:text-lg leading-relaxed text-gray-700 prose max-w-none">
                            {mainContent.length > 0 ? <BlocksRenderer content={mainContent} /> : ""}
                        </div>
                    </section>
                </article>

                {mediaUrls && mediaUrls.length > 0 && (
                    <PhotoPlayer media={mediaUrls} url={url} />
                )}
            </main>
            <Footer />
        </>
    );
};

export default TypetwoPage;
