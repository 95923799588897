import React, { useLayoutEffect, useState, useEffect } from 'react';
import { Link, useLocation, useParams } from "react-router-dom";
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import NoutatiSmallSideBar from '../components/NoutatiSmallSideBar/NoutatiSmallSideBar';
import NoutatiRecenteSidebar from '../components/NoutatiRecenteSidebar/NoutatiRecenteSidebar';
import ViewIcon from '../assets/site_assets/lets-icons_view-light.svg';
import Print from '../assets/site_assets/print.svg';
import PhotoPlayer from '../components/MediaPlayer/PhotoPlayer';
import Distribuie from '../components/Distribuie/Distribuie';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import useFetchCards from '../Hooks/useFetchCards';
import Loader from '../components/Loader/Loader';

const Noutati = () => {
    const location = useLocation();
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    const currentPageUrl = window.location.href;
    const { header } = useParams();
    const { images, url } = useFetchCards();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedNews, setSelectedNews] = useState(null);

    useEffect(() => {
        if (images && images.length > 0) {
            try {
                const news = images.find((item) => item?.attributes?.Titlu === header);
                if (news) {
                    setSelectedNews(news);
                    setError(false);
                } else {
                    setError("News not found.");
                }
            } catch (err) {
                setError("Failed to fetch news.");
            }
        }
    }, [images, header]);

    // Format the creation date if selectedNews exists
    let formattedDate;
    if (selectedNews?.attributes?.createdAt) {
        const date = new Date(selectedNews.attributes.createdAt);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        formattedDate = `${day}.${month}.${year}`;
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 500);

        return () => clearTimeout(timer);
    }, []);
        return (
            <>
                {loading && (
                    <div className="fixed inset-0 bg-white flex justify-center items-center z-50">
                        <Loader size={30} />
                    </div>
                )}
                <Header />
                <div className="container mx-auto">
                    <main className="px-4 md:px-8 lg:px-16 pt-8 pb-12 grid grid-cols-1 lg:grid-cols-3 gap-8">
                        <div className="md:col-span-1">
                            <NoutatiSmallSideBar />
                            <NoutatiRecenteSidebar />
                        </div>

                        {loading ? (
                            <div className="flex justify-center items-center min-h-[300px] w-full col-span-2">
                                <Loader size={30} />
                            </div>
                        ) : error ? (
                            <div className="col-span-2 text-red-500 flex justify-center items-center min-h-[300px]">
                                {error}
                            </div>
                        ) : selectedNews ? (
                            <div className="md:col-span-2 relative">
                                <div className="">
                                    <span className='flex gap-[12px]'>
                                        <Link className='opacity-[0.4]' to={'/'}>Acasa</Link>
                                        /
                                        <p>{selectedNews.attributes.Titlu || ""}</p>
                                    </span>
                                </div>

                                {/* Main Image with fallback checks */}
                                <div className="relative mt-[28px]">
                                    {selectedNews?.attributes?.CoverBanner?.data?.attributes?.url && (
                                        <img
                                            className="w-full md:max-h-[426px] h-auto object-cover"
                                            src={`${url}${selectedNews.attributes.CoverBanner.data.attributes.url}`}
                                            alt=""
                                            width={selectedNews?.attributes?.CoverBanner?.data?.attributes?.width || ""}
                                            height={selectedNews?.attributes?.CoverBanner?.data?.attributes?.height || ""}
                                        />
                                    )}

                                    <div className="container absolute px-7 -bottom-8 md:-bottom-10 left-0 w-full z-10">
                                        <div className="bg-white w-full p-6">
                                            <p className="text-wrap sm:text-3xl font-medium leading-snug truncate line-clamp-2">
                                                {selectedNews.attributes.Titlu || ''}
                                            </p>
                                            <div className="flex sm:justify-between flex-col sm:flex-row mt-5 items-start gap-2">
                                                <span className="text-[#161616CC] flex gap-4 opacity-80 font-light tracking-wide leading-5 text-sm md:text-base">
                                                    <data value="">{formattedDate || ""}</data>
                                                    <span className="flex gap-2">
                                                        <img className="w-5 h-5" src={ViewIcon} alt="" />
                                                        <p>50</p>
                                                    </span>
                                                </span>

                                                <span className="flex gap-2 items-center">
                                                    <button onClick={()=>window.print()} className="flex text-[#4F7DF2] text-sm md:text-base">
                                                        <img src={Print} alt="" />
                                                        <span className='ml-2 font-thin'>Imprimare</span>
                                                    </button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Description and BlocksRenderer */}
                                <p className='text-sm md:text-base text-[#161616CC] opacity-80 mt-20 md:mt-20 mx-4 md:mx-6 lg:mx-10 text-pretty prose max-w-none'>
                                    {selectedNews?.attributes?.DescriereJSON ? (
                                        <BlocksRenderer content={selectedNews.attributes.DescriereJSON} />
                                    ) : (
                                        'Nu exista text'
                                    )}
                                </p>

                                {/* Render PhotoPlayer only if media exists */}
                                {selectedNews?.attributes?.Media?.data?.length > 0 && (
                                    <PhotoPlayer media={selectedNews.attributes.Media.data} url={url} />
                                )}

                                <Distribuie page_url={currentPageUrl} />
                            </div>
                        ) : null}
                    </main>
                </div>
                <Footer />
            </>
        );
    };

    export default Noutati;
