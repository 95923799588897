import React from 'react';
import { Link } from 'react-router-dom';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';

const NewsCard = ({ img, header, content, date, classes, imgClass, readMore }) => {
    return (
        <Link to={`/noutati/${header}`} className='flex flex-col md:flex-row w-full bg-white rounded-[10px] p-[16px] gap-[20px] md:h-[161px] 
                        transition-all transform duration-300 hover:shadow-lg hover:cursor-pointer'>
            {/* Image Section */}
            <div className={`${classes} lg:h-[120px] h-auto flex-shrink-0`}>
                <img
                    src={img ? img : "https://via.placeholder.com/100x100"}
                    alt="news image"
                    className={`${imgClass} object-cover rounded-[5px] mx-auto`}
                />
            </div>

            {/* Content Section */}
            <div className="flex flex-col justify-between w-full overflow-hidden">
                {/* Header and Content */}
                <div className="flex flex-col flex-grow">
                    <h1 className='font-medium text-[10px] md:text-[24px] leading-7 text-[#161616] md:h-[62px] truncate line-clamp-2 prose'>
                        {header ? header : "Nu exista titlu"}
                    </h1>
                    <div className='text-[#161616CC] text-[16px] leading-6 truncate md:pr-[40px] line-clamp-2'>
                        {content ? <BlocksRenderer content={content} /> : "Nu exista continut"}
                    </div>
                </div>

                {/* Date and Link */}
                <div className="flex justify-between items-center mt-[8px] md:mt-[0px]">
                    <span className='text-[#16161699] text-[14px] flex-shrink-0 truncate'>{date ? date : ""}</span>
                    <Link
                        to={`/noutati/${header}`}
                        className='underline text-[#4F7DF2] md:hover:text-[#3a5ec4] transition-colors duration-300 text-[14px] md:pr-[40px]
                        border-2 border-[#4F7DF2] rounded-md p-2 hover:bg-main hover:text-white md:border-0 md:hover:bg-transparent md:p-0'
                    >
                        {readMore ? readMore : ""}
                    </Link>
                </div>
            </div>
        </Link>
    );
}

export default NewsCard;
