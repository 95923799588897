import React from 'react';

const HeaderText = ({ classes, text }) => {
  return (
    <div>
      <h1 className={`text-[#010009] text-[30px] leading-[40px] 
                     md:text-[44px] md:leading-[34px] ${classes}
                     `}>
        {text}
      </h1>
    </div>
  );
};

export default HeaderText;
